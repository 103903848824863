@import 'src/styles/variables';
@import 'src/styles/media';

.comparisonTable {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
}

.comparisonTable th,
.comparisonTable td {
  border: 1px solid $black10;
  border-right: 0;
  padding: 5px 4px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 0;
}

.comparisonTable thead th {
  background-color: $black6;
  border-bottom: 0;
  min-width: 63px;
  font-weight: $mediumFontWeight;
}

.comparisonTable thead th:last-child,
.comparisonTable tr td:last-child {
  background-color: $purple50;
  border-right: 1px solid $black10;
}

.thWithRadius {
  border-top-left-radius: 10px;
}

.comparisonTable th:first-child {
  background-color: transparent;
  border: 0;
}

.comparisonTable th:last-child {
  border-top-right-radius: 10px;
}

.comparisonTable td {
  color: $black80;
  line-height: 22px;

  svg {
    vertical-align: middle;
  }
}

.comparisonTable tr td:first-child {
  border-left: 0;
  padding: 12px 8px 12px 0;
  font-weight: $mediumFontWeight;
  color: $black100;
}

.comparisonTable tr:last-child td {
  border-bottom: 1px solid $black10;
}

.highlightText {
  font-weight: $boldFontWeight;
  color: $purple400 !important;
}

.comparisonTable th:first-child,
.comparisonTable td:first-child {
  text-align: left;
}

.purpleCheckIcon path {
  fill: $purple400;
}

.grayCrossIcon path {
  stroke: $black60;
}
