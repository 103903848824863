@import '../../../styles/mixins';
@import '../../../styles/variables';

.benefitsContainer {
  @include verticalGap(20px);
  padding: 20px;
  border: 1px solid $black10;
  border-radius: 12px;
  box-shadow: $deepBoxShadow;
}

.benefitsCard {
  display: flex;
  gap: 12px;
  flex-direction: row;
}

.benefitCardIcon {
  width: 40px;
  height: 40px;
}

.benefitTile {
  font-weight: $boldFontWeight;
  line-height: 28px;
}

.benefitDescription {
  span {
    font-weight: $mediumFontWeight;
    color: $blue400;
  }
}

.lineSpacing {
  width: 100%;
  height: 1px;
  background-color: $black10;
}
