@import '../../../styles/mixins';
@import '../../../styles/variables';

.subtitle {
  @include subtitle;
  @include verticalGap(0);
  align-items: center;
  font-size: 16px;
  color: $black100;

  span {
    font-weight: $mediumFontWeight;
    color: $blue400;
  }
}

.comparisonContainer {
  @include verticalGap(16px);
}

.comparisonHeader {
  text-align: center;
  line-height: 28px;

  span {
    font-weight: $boldFontWeight;
  }
}

.disclaimerContainer {
  font-weight: $regularFontWeight;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: $black80;
  line-height: 20px;

  span {
    font-weight: $boldFontWeight;
  }

  @include sm {
    font-size: 14px;
  }
}
